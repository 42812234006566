import React from "react";
import "./Card.css";

export default function Contact(props) {
  const handleSubmit = (e) => {
    e.PreventDefault();
  };

  return (
    <div className="formCard">
      <div className="formLeft">
        <h2> Get In Touch With Farawa</h2>
        <p>We would love to know what you want. We cant solve your problem if you dont tell us about it.</p>

      </div>
      <div className="formRight">
        
      <form onSubmit={handleSubmit}>
                <div className="flex">
                    <div className="flex1">
                        <label>First Name</label>
                        <input type="text" placeholder="First Name"/>
                    </div>

                    <div className="flex2">
                        <label>Last Name</label>
                        <input type="text" placeholder="Last Name"/>
                    </div>
                </div>
                <div className="mT">
                    <div className="flex1">
                        <label>Email Address</label>
                        <input type="text" placeholder="First Name"/>
                    </div>
                </div>
                <div className="mT">
                    <div className="flex1">
                        <label>Messages</label>
                        <textarea placeholder="Your Messages" rows="5"/>
                    </div>
                </div>
                <button >SEND MESSAGES</button>
            </form>
      </div>
    </div>
  );
}
