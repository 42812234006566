import videoBg from "../../purple/src/pexels-rodnae-productions-7821852 (3).mp4";
import { FaBeer } from 'react-icons/fa';
import './App.css';
import Nav from "./Component/Nav";
import Frame1 from "./Component/Frame1";
import Footer from "./Component/Footer";
import Home from "./Pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./Pages/About";

function App() {
  return (
    <BrowserRouter>
    <div className="App">
     <Nav/>
     <Routes>
     <Route path="/" element={<Home  />} />
     
     <Route path="/contact" element={<About/>} />
     </Routes>
     
      
      
     
  

<Footer/>
    </div>
    </BrowserRouter>
    
  );
}

export default App;
