import React from 'react'
import "./Pricing.css"
export default function Pricing(props) {
    

    return (
        <div >
             <div className='service'>
        
        <h1>What Service Do We Have For Your Business</h1>
        <p>We unite our talent, technology, products and services so that we design and
deliver sustainable digital solutions that enable businesses to upgrade their growth</p>
      </div>
           <section class="main-container">
    <div class="card-container">
      <div class="pricing-card card-01">
        <div class="pricing">
          <div class="price">
            {/* <sup>$</sup> */}
            <span>1</span>
          </div>
          <p>BRAND</p>
          <span class="type">GUIDELINE</span>
        </div>
        <div class="card-body">
          <div class="top-shape"></div>
          <div class="card-content">
            <ul>
              <li class="active">Logo <i class="uil uil-check-circle"></i></li>
              <li class="active">Logo Guide <i class="uil uil-check-circle"></i></li>
              <li class="active">Core color palette <i class="uil uil-times-circle"></i></li>
              <li class="active"> Typography <i class="uil uil-times-circle"></i></li>
              <li class="active">Voice <i class="uil uil-times-circle"></i></li>
              <li class="active">Imagery <i class="uil uil-times-circle"></i></li>
              <li class="active">Portfolio/Testimonials <i class="uil uil-times-circle"></i></li>
            </ul>
            <button class="buy-btn">$1,500 </button>
          </div>
        </div>
        <div class="ribbon"></div>
      </div>
      <div class="pricing-card card-02">
        <div class="pricing">
          <div class="price">
            {/* <sup>$</sup> */}
            <span>2</span>
          </div>
          <p>DIGITAL   </p>
          <span class="type"> ASSET MOCKUPS</span>
        </div>
        <div class="card-body">
          <div class="top-shape"></div>
          <div class="card-content">
            <ul>
              <li class="active">Stationery <i class="uil uil-check-circle"></i></li>
              <li class="active">Office supplies<i class="uil uil-check-circle"></i></li>
              <li class="active">Gigt items <i class="uil uil-check-circle"></i></li>
              <li class="active">Wearables <i class="uil uil-times-circle"></i></li>
              <li class="active">Social Media <i class="uil uil-times-circle"></i></li>
              <li class="active">Ads/Marketing <i class="uil uil-times-circle"></i></li>
              <li class="active">Product design <i class="uil uil-times-circle"></i></li>
            </ul>
            <button class="buy-btn">$1,000</button>
          </div>
        </div>
        <div class="ribbon"></div>
      </div>
      <div class="pricing-card card-03">
        <div class="pricing">
          <div class="price">
            {/* <sup>$</sup> */}
            <span>3</span>
          </div>
          <p>CORPORATE</p>
          <span class="type"> PROFILE</span>
        </div>
        <div class="card-body">
          <div class="top-shape"></div>
          <div class="card-content">
            <ul>
              <li class="active">Mission/Vision <i class="uil uil-check-circle"></i></li>
              <li class="active">Corporate History <i class="uil uil-check-circle"></i></li>
              <li class="active">Service Offering <i class="uil uil-check-circle"></i></li>
              <li class="active">Business Strategy<i class="uil uil-check-circle"></i></li>
              <li class="active">Industry positioning <i class="uil uil-check-circle"></i></li>
              <li class="active"> Team/Structure <i class="uil uil-check-circle"></i></li>
              <li class="active"> Case Studies/ Call to Action <i class="uil uil-check-circle"></i></li>
            </ul>
            <button class="buy-btn">$1,000</button>
          </div>
        </div>
        <div class="ribbon"></div>
      </div>

      <div class="pricing-card card-03">
        <div class="pricing">
          <div class="price">
            {/* <sup>$</sup> */}
            <span>4</span>
          </div>
          <p>CORPORATE</p>
          <span class="type">WEBSITE</span>
        </div>
        <div class="card-body">
          <div class="top-shape"></div>
          <div class="card-content">
            <ul>
              <li class="active">User stories flow <i class="uil uil-check-circle"></i></li>
              <li class="active"> UI/UX design <i class="uil uil-check-circle"></i></li>
              <li class="active">Front-end build <i class="uil uil-check-circle"></i></li>
              <li class="active"> Back-end Build <i class="uil uil-check-circle"></i></li>
              <li class="active">Hosting <i class="uil uil-check-circle"></i></li>
              <li class="active">Chatbot Integration <i class="uil uil-check-circle"></i></li>
              <li class="active">Blog<i class="uil uil-check-circle"></i></li>
            </ul>
            <button class="buy-btn">$3,500</button>
          </div>
        </div>
        <div class="ribbon"></div>
      </div>

    </div>
  </section>

                <div className='p-btn'>
                    <button>GET A QUOTE </button>
                </div>
        </div>
    )
}
