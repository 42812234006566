import React from 'react'
import AboutFrame2 from '../Component/About/AboutFrame2'
import AboutHero from '../Component/About/AboutHero'
import Card from '../Component/About/Card'
import Contact from '../Component/About/Contact'
import ImageFrame1 from '../Component/About/ImageFrame1'

export default function About(props) {
    

    return (
        <div >
            {/* <AboutHero/>
            <ImageFrame1/>
            <Card/> */}
            <AboutFrame2/>
            <Contact/>
        </div>
    )
}
