import React,{useState} from 'react'
import "../App.css"
import { useNavigate } from "react-router-dom";
import videoBg from "../../src/pexels-rodnae-productions-7821852 (3).mp4"
import Frame1 from '../Component/Frame1'
import Home2 from '../Component/Home2'
import Why from '../Component/Why';
import Image from '../Component/Image';
import{SiLivejournal} from "react-icons/si";
import Ankara from '../Assest/Ankara.mp4'
import Card from '../Component/About/Card';
import Pricing from '../Component/Pricing';

export default function Home(props) {
    const [email, setEmail] = useState()
    const [error, setError] = useState(false)
    
    let navigate = useNavigate();
    const handleSubmit = (e) =>{
        e.preventDefault()
        if(email.length < 5 ){
           setError(true)
        }else{
            navigate('/contact')
        }
       
    }
    
  
    return (
        <>
            <div id="outer">
            <div class="overlay"></div>
            <p></p>
    <div id="home-top-video">
     
       <video src={Ankara } autoPlay loop muted width="100%" /> 
      
    </div>
    <div className='videoTextContainer'>
      <div className='videoTextSec'>
        <h2>Your No 1</h2>
        <h3>Afri-global Marketing, Media and PR partner</h3>
       
       
        <form className="HeroForm" onSubmit={handleSubmit}>
          <div className='cta'>
          <input placeholder="Your Email Address" type="text" onChange={(e)=>setEmail(e.target.value)}/> <button>START HERE</button>
          </div>
       
        </form>
      </div>
      </div>
  </div> 
        <div className='vy'>
        <h2 style={{ color: "black"}}>
          OUR  MISSION  < SiLivejournal style={{marginLeft:"12px", color: "black"}}/>
            </h2>
            <p style={{marginTop:"19px", color: "black", fontWeight: 100}}>FARAWA is committed to empowering African
businesses to succeed in the global economy through expert marketing and PR services and
the development of meaningful connections with businesses around the world. We are also dedicated to facilitating global businesses in effectively doing business in African markets, helping them to thrive and prosper in this dynamic and rapidly-growing region.</p>
        </div>
 <Why/>
<Pricing/>
 <Image/>
  <Home2/>
  
        </>
    )
}
