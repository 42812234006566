import React from 'react'
import "./Home2.css"
import {MdRecordVoiceOver} from "react-icons/md";
import {GiTripleCorn,GiRamProfile} from "react-icons/gi"
import {VscWorkspaceTrusted} from "react-icons/vsc"
import {BsPatchQuestion} from "react-icons/bs"
export default function Home2(props) {
    

    return (
        <div className='AFrame5Home'>
<div className='AAboutFrame5'>
            <div className='AAboutFrame5Section1'>
                {/* <p>Why Choose Us <BsPatchQuestion style={{marginLeft:"12px"}} /></p> */}
                {/* <h1>The Best Decision For Your Business and Media Strategy</h1> */}
                <h1>OUR PROMISE</h1>
                <p>Our vision at FARAWA is to enable African businesses to reach their full potential in the global
market and to facilitate global businesses in effectively doing business in African markets,
creating a thriving and interconnected business ecosystem.</p>

            </div>
            <div className='AAboutFrame5Section2'>
            <h1>WHAT MATTERS TO US</h1>
            <div className='valueFlex'>
                
           <div className='valueIcon'>
           <GiTripleCorn  /> 
           </div>
           <div>
                <h1>Value  </h1>
                </div>
            </div>
                   
                    <p>FARAWA is committed to providing the best value for money to its clients
through its services, always striving to deliver maximum return on investment.</p>
<div className='valueFlex'>
                
                <div className='valueIcon'>
                <MdRecordVoiceOver  /> 
                </div>
                <div>
                     <h1>Expertise </h1>
                     </div>
                 </div>
                    {/* <h1> <MdRecordVoiceOver style={{marginLeft:"12px"}} /></h1> */}
                    <p>FARAWA is dedicated to offering expert marketing and PR services to its
clients, backed by a wealth of knowledge and experience in the industry.</p>
<div className='valueFlex'>
                
                <div className='valueIcon'>
                <VscWorkspaceTrusted  /> 
                </div>
                <div>
                     <h1>Professionalism  </h1>
                     </div>
                 </div>
                    {/* <h1> <VscWorkspaceTrusted style={{marginLeft:"12px"}}/></h1> */}
                    <p>FARAWA values professionalism and maintains high standards in its
work, always adhering to industry best practices.</p>
<div className='valueFlex'>
                
                <div className='valueIcon'>
                <GiRamProfile  /> 
                </div>
                <div>
                     <h1>Trust  </h1>
                     </div>
                 </div>
                    {/* <h1> < style={{marginLeft:"12px"}}/> </h1> */}
                    <p>FARAWA is committed to building trust with its clients through honesty,
transparency, and reliable delivery of services.</p>
            </div>
        </div>
        </div>
        
    )
}
