import React from "react";
import "./Why.css";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Client1 from "../Assest/adanian.png";
import Client2 from "../Assest/afya.png";
import Client3 from "../Assest/basira (1).png";
import Client4 from "../Assest/betalife.png";
import Client5 from "../Assest/ellamediate.png";
import Client6 from "../Assest/impresit.png";
import Client7 from "../Assest/kiowope.png";
import Client8 from "../Assest/plp.png";
import Client9 from "../Assest/velex.png";
import Client10 from "../Assest/vertex.png";
export default function Image(props) {
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        autoplay: false,
      speed: 2000,
      autoplaySpeed: 1000,
      cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    return (
    <div className="newImageVal">
      <div>
        <h2> THEY TRUST US </h2>
        <Slider {...settings}>
          <div className="client11">
          <img src={Client1} alt="CC" />
          </div>
          <div className="client11">
          <img src={Client2} alt="CC" />
          </div>
          <div className="client11">
          <img src={Client3} alt="CC" />
          </div>
          <div className="client12">
          <img src={Client4} alt="CC" />
          </div>
          <div className="client11">
          <img src={Client5} alt="CC" />
          </div>
          <div className="client11">
          <img src={Client6} alt="CC" />
          </div>
          <div className="client11">
          <img src={Client9} alt="CC" />
          </div>
          <div className="client13">
          <img src={Client10} alt="CC" />
          </div>
         
        </Slider>
      </div>
    </div>
  );
}
