import React from 'react'
import { FaWhatsappSquare,FaInstagramSquare,FaLinkedin,FaPinterestSquare,FaTwitterSquare } from 'react-icons/fa' 
import "./Nav.css"
export default function Footer(props) {
    

    return (
        <div className='footerContainer'>

<div className='footer'>
            <div className='footerLogo'>
                <h2>About Us</h2>
                <p>Our vision at FARAWA is to enable African businesses to reach their full potential in the global market and to facilitate global businesses in effectively doing business in African markets, creating a thriving and interconnected business ecosystem.</p>
            </div>

            <div className='ContactUs'>
                <h4> Contact Us</h4>
               
                <p>2nd Floor, Mulliner Towers,<br/> 30 Alfred Rewane Road Ikoyi , Lagos.</p>
                <div className='menuFooter'>
                <a href="https://www.instagram.com/_farawa" target="_blank" rel="noreferrer noopener">
                <FaInstagramSquare className='iconFooter'/>
                </a>
                <a href="mailto:hello@farawa.xyz" target="_blank" rel="noreferrer noopener">
                <FaPinterestSquare className='iconFooter'/>
                </a>
                      
                        <a href="https://twitter.com/neonglobal_" target="_blank" rel="noreferrer noopener">
                        <FaTwitterSquare className='iconFooter'/>
                        </a>
                        <a href="https://www.instagram.com/_farawa" target="_blank" rel="noreferrer noopener">
                        <FaLinkedin className='iconFooter'/>
                </a>
                        
                    </div>
            </div>
                
            <div className='Legals'>
                <h4>START NOW</h4>
                <p>Kindly Input your email to get started</p>
               <form>
                <div className='fta'>
                <input  placeholder='Enter your Email'/> <button >Start Now</button>
                
                </div>
                
               </form>
            </div>
            
        </div>



        </div>
       
    )
}
