import React from "react";
import Contact from "./Contact";
import "./style.css";
export default function AboutFrame2(props) {
  

  return (
    <div className="AboutFrame2">
      <p> Welcome to FARAWA!</p>
      {/* <h1>We Are Changing the Way People Think About Media and PR service</h1> */}
      <p>
        Thank you for signing up on our landing page and for considering us for
        your marketing, media, and PR needs. My name is Killian MAYUA and I am the Founder and CEO of
        FARAWA.
      </p>
      <p>
      At FARAWA, we are passionate about helping our clients succeed. We offer a range of services
through our six service units: iBrandEx for branding, iPrintEx for printing, iMarketEx for
marketing and business consulting, iCoverEx for videography and photography, and iEventEx
for event management. We have the expertise and experience to help you succeed.
      </p>
      <p> 
      We value your trust and appreciate the opportunity to work with you. At FARAWA, we strive to
make you feel safe, appreciated, and supported as we work together to help you succeed and
grow. We are excited to get started and make a positive impact on your business. We are a
company founded on the belief that everyone deserves the opportunity to succeed.
      </p>

      <p>
      At FARAWA, we understand that each client is unique and we strive to provide personalized
solutions that meet your specific needs. Our team is committed to delivering high-quality results
and we take pride in the success of our clients. We are excited to work with you and to help you
achieve your goals.
      </p>
      <p>Thank you for choosing FARAWA, we look forward to the opportunity to serve you.</p>
      <p>Killian MAYUA<br/>
Founder | CEO, FARAWA.</p>

    
    </div>
  );
}
