import React from "react";
import { MdOutlineInsights } from "react-icons/md";
import{TbBrandTelegram} from "react-icons/tb";
import { AiOutlinePieChart, AiFillGift } from "react-icons/ai";
import { HiOutlineVideoCamera } from "react-icons/hi";
import { AiOutlineTeam, AiOutlineGold} from "react-icons/ai";
import {FcPrint} from "react-icons/fc"
import "./Why.css";
export default function Why(props) {
  return (
    <div className="WhyContainer">
      <div className="WhyLeft">
        <h1>WHY FARAWA </h1>
       
        <p className="whyText1">
          We started FARAWA because we have a passion for helping others succeed
          and a desire to use our creativity and skills to craft compelling
          marketing campaigns and events. We offer a range of services through
           our six service units:
        </p>
      </div>

      <div className="WhyRight">
        <div className="rightSection1">
          <div className="ContentDiv1">
         
                <div className="whyFlexNew">
                <div className="flexIconNew">
                < TbBrandTelegram  />
                </div>
                <div >
                    <p>iBrandEx</p>
                </div>
                </div>
            
               
                    <p> Branding | Developing a strong brand identities.</p>
                
           
          </div>
          <div className="ContentDiv2">
          <div className="whyFlexNew">
          <div className="flexIconNew">
          <FcPrint  />
                </div>
                <div >
                    <p>iPrintEx</p>
                </div>
</div>
<div className="detailsDiv">
<div>
                    <p>Printing | Creating eye-catching marketing materials. </p>
                </div>
</div>
          </div>
        </div>

        <div className="rightSection2">
          <div className="ContentDiv3">
          <div className="whyFlexNew">
          <div className="flexIconNew">
          <AiFillGift  />
                </div>
                <div >
                    <p>iEventEx</p>
                </div>
</div>
<div className="detailsDiv">
<div>
                    <p>Event Management | Planning and executing a
successful events.</p>
                </div>
</div>
          </div>
          <div className="ContentDiv4">
          <div className="whyFlexNew">
          <div className="flexIconNew">
          <MdOutlineInsights  />
                </div>
                <div >
                    <p>iMarketEx</p>
                </div>
</div>
<div className="detailsDiv">
<div>
                    <p>360 Marketing | Defining and  delivering marketing
goals and objectives.</p>
                </div>
</div>
            
          </div>
        </div>
        <div className="rightSection3">
          <div className="ContentDiv5">
          <div className="whyFlexNew">
                <div className="flexIconNew">
                <HiOutlineVideoCamera />
                </div>
                <div >
                    <p>iCoverEx</p>
                </div>
            </div>
            <div className="detailsDiv">
                <div>
                    <p>Videography/Photography | Providing coverage services that help you capture and
produce high-quality video and audio contents.</p>
                </div>
            </div>
          </div>
          <div className="ContentDiv6">
          <div className="whyFlexNew">
                <div className="flexIconNew">
                <AiOutlineTeam />
                </div>
                <div >
                    <p>iConsultEx</p>
                </div>
            </div>
            <div className="detailsDiv">
                <div>
                    <p>Business Consulting | Creating substainable structure
and systems for your business success.</p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
