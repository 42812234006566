import React from 'react'
import './Nav.css';
import Logo from "./LO.png";
import LOg from "./favicon16.png"
import { FaWhatsappSquare,FaInstagramSquare,FaLinkedin,FaPinterestSquare,FaTwitterSquare } from 'react-icons/fa' 
import {ImMail} from 'react-icons/im' 
export default function Nav(props) {
    

    return (
        <nav className='sticky'>

            <div className='nav'>
            <div className='Logo1'>
            <img src={Logo} alt="Farawa"/>
            </div>

            <div className='text'>
                    <div className='menu'>
                    <a href="https://api.whatsapp.com/send?phone=2348121631895&text=Hello Farawa" target="_blank" rel="noreferrer noopener">
                    <FaWhatsappSquare className='icon'/>
                </a>
                        
                        <a href="https://www.instagram.com/farawamedia" target="_blank" rel="noreferrer noopener">
                <FaInstagramSquare className='icon'/>
                </a>
                <a href="mailto:hello@farawa.xyz" target="_blank" rel="noreferrer noopener">
                <ImMail className='icon'/>
                </a>
                      
                        <a href="https://twitter.com/farawamedia" target="_blank" rel="noreferrer noopener">
                        <FaTwitterSquare className='icon'/>
                        </a>
                        
                    </div>
            </div>
      
            </div>
    </nav>
    )
}
